import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetCompanysQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  useCreateAreaMutation,
  useCreateCompanyAreasMutation,
  useGetCompanyAreasQuery,
  useUpdateCompanyAreaMutation,
} from "../../libs/redux/slices/crearexxSlice/crearexxApiSlice";
import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";
import { useDispatch } from "react-redux";

const Crearexx = ({ onClose, isCreating, row, isEdit }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));

  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [insertArea, { isLoading: isLoadingArea }] = useCreateAreaMutation();
  const [insertCompanyAreas, { isLoading: isLoadingCompanyAreas }] = useCreateCompanyAreasMutation();
  const [upCompanyAreas, { isLoading: isUpdatingCompanyAreas }] = useUpdateCompanyAreaMutation();

  const [validated, setValidated] = useState(false);
  const [descriptionArea, setDescriptionArea] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [nitsCompanys, setNitsCompanys] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [companiesValid, setCompaniesValid] = useState(true);

  // Hook para cargar empresas relacionadas al area
  const { data: companyAreasData, isLoading: isLoadingCompanyAreasData } = useGetCompanyAreasQuery(row?.AREAIDXX, {
    skip: !row?.AREAIDXX,
  });

  // Efecto para inicializar los valores en modo edicion o visualizacion
  useEffect(() => {
    if (row) {
      setDescriptionArea(row.AREADESX || "");
      setDescriptionPath(row.AREAPATH || "");
    }
  }, [row]);

  // Efecto para agregar las empresas asociadas al area al array nitsCompanys
  useEffect(() => {
    if (companyAreasData?.data) {
      const newNits = companyAreasData.data.map((company) => company.EMPNITXX);
      setNitsCompanys((prevNits) => [...prevNits, ...newNits]);
    }
  }, [companyAreasData]);

  useEffect(() => {
    if (isCreating) {
      setDescriptionArea("");
      setDescriptionPath("");
      setNitsCompanys([]);
      setValidated(false);
      setCompaniesValid(true);
    }
  }, [isCreating]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() && nitsCompanys.length > 0) {
      if (isCreating) {
        // Logica cuando isCreating es true (Crear un area nueva)
        await insertArea({ AREADESX: descriptionArea, AREAPATH: descriptionPath })
          .unwrap()
          .then((response) => {
            saveCompanyAreas(response.AREAIDXX);
          })
          .catch((error) => {
            console.error("Error creating area:", error);
          });
      } else {
        // Logica cuando isCreating es false (Editar area existente)
        if (row && row.AREAIDXX) {
          await updateCompanyAreas(row.AREAIDXX, descriptionArea)
            .then(() => {
              console.log("Company areas updated successfully.");
            })
            .catch((error) => {
              console.error("Error updating company areas:", error);
            });
        } else {
          console.error("No AREAIDXX provided for editing.");
        }
      }
    } else {
      if (nitsCompanys.length === 0) {
        setCompaniesValid(false);
      }
    }
    setValidated(true);
  };

  const saveCompanyAreas = async (areaID) => {
    await insertCompanyAreas({ EMPNITXX: nitsCompanys, AREAIDXX: areaID })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  const updateCompanyAreas = async (areaID, descriptionArea) => {
    await upCompanyAreas({ EMPNITXX: nitsCompanys, AREAIDXX: areaID, AREADESX: descriptionArea })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue && !nitsCompanys.includes(selectedValue)) {
      setNitsCompanys([...nitsCompanys, selectedValue]);
      setShowSelect(false);
      setCompaniesValid(true);
    }
  };

  const removeCapsule = (value) => {
    setNitsCompanys(nitsCompanys.filter((item) => item !== value));
    if (nitsCompanys.length === 1) {
      setCompaniesValid(false);
    }
  };

  return isLoadingArea || isLoadingCompanyAreas || isLoadingCompanyAreasData || isUpdatingCompanyAreas ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Nombre*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionArea}
              type="text"
              required
              onChange={(e) => setDescriptionArea(e.target.value)}
              disabled={isEdit}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Area</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => setDescriptionPath(e.target.value)}
              minLength={8}
              maxLength={8}
              disabled={!isCreating}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={12}>
          <Form.Label>Empresa:*</Form.Label>

          <div className="mb-3">
            {nitsCompanys.map((nit) => (
              <Row key={nit} className="align-items-center mb-2">
                <Col md={10} style={{ backgroundColor: '#fff', color: '#000', padding: '8px 12px', borderRadius: '4px' }}>
                  {companys?.data?.find((company) => company.EMPNITXX === nit)?.EMPDESXX}
                </Col>
                <Col md={2}>
                  <Button variant="warning" size="sm" onClick={() => removeCapsule(nit)} disabled={isEdit}>
                    &times;
                  </Button>
                </Col>
              </Row>
            ))}
          </div>

          {showSelect && (
            <Form.Select value="" onChange={handleSelectChange} isInvalid={!companiesValid} disabled={isEdit}>
              <option value={""}>[SELECCIONE]</option>
              {isLoadingCompanys ? (
                <option value={""}>CARGANDO...</option>
              ) : (
                companys?.data?.map((company) => (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                ))
              )}
            </Form.Select>
          )}

          {!isEdit && (
            <Button variant="primary" className="mt-2" onClick={() => setShowSelect(!showSelect)}>
              {showSelect ? "Cancelar" : "+"}
            </Button>
          )}

          <Form.Control.Feedback type="invalid" className="d-block">
            {companiesValid ? null : "Debe Seleccionar al menos una Empresa"}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          {!isEdit && <Button type="Submit">Guardar</Button>}
        </Col>
        <Col md={1}>
          <Button
            onClick={() => {
              setValidated(false);
              setDescriptionArea("");
              setDescriptionPath("");
              setNitsCompanys([]);
              setCompaniesValid(true);
            }}
            disabled={isEdit}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Crearexx;
