import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetCompanysQuery, useLazyGetAreasByCompanyQuery, useLazyGetSeriesByAreaQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  useCreateSerieMutation,
  useCreateCompanyAreaSerieMutation,
  useUpdateSerieMutation,
  useUpdateCompanyAreaSerieMutation,
} from "../../libs/redux/slices/creserxxSlice/creserxxApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";

const Creserxx = ({ onClose, isCreating, row, isEdit }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [findAreasByCompany] = useLazyGetAreasByCompanyQuery();
  const [insertSerie, { isLoading: isLoadingSerie }] = useCreateSerieMutation();
  const [updatetSerie, { isLoading: isLoadingUpdateSerie }] = useUpdateSerieMutation();
  const [upCompanyAreaSerie] = useUpdateCompanyAreaSerieMutation();
  const [insertCompanyAreaSerie, { isLoading: isLoadingCompanyAreaSerie }] = useCreateCompanyAreaSerieMutation();
  const [validated, setValidated] = useState(false);
  const [serieId, setSerieId] = useState(false);
  const [serieDescription, setSerieDescription] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [companyArea, setCompanyArea] = useState([{ EMPNITXX: "", AREAIDXX: "" }]);
  const [triggerGetSerieEmpresa, { isLoading: isLoadingAreasSeries }] = useLazyGetSeriesByAreaQuery();

  useEffect(() => {
    companyArea.forEach((relation, index) => {
      if (relation.EMPNITXX !== "") {
        findAreasByCompany({ EMPNITXX: relation.EMPNITXX, AREAIDXX: "null" }).catch(() => console.log("Error al cargar áreas"));
      }
    });
  }, [companyArea, findAreasByCompany]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (isCreating) {
      // Logica cuando isCreating es true (Crear un AREAIDXX nueva)
      if (form.checkValidity() && companyArea.every(relation => relation.AREAIDXX !== "")) {
        await insertSerie({ SERIEDES: serieDescription, SERIEPAT: descriptionPath })
          .unwrap()
          .then((response) => {
            companyArea.forEach((relation) => {
              saveCompanyAreaSerie(response.data.SERIEIDX, relation.EMPNITXX, relation.AREAIDXX);
            });
          })
          .catch();
      } else {
        event.preventDefault();
      }
      setValidated(true);
    } else {
      // Logica cuando isCreating es false (Editar AREAIDXX existente)
      await updatetSerie({ SERIEIDX: serieId, SERIEDES: serieDescription, SERIEPAT: descriptionPath, REGESTXX: "ACTIVO" })
      .unwrap()
      .then((response) => {
        updateCompanyAreaSerie(serieId, companyArea);
      })
      .catch();
    }
  };

  const saveCompanyAreaSerie = async (serieID, EMPNITXX, AREAIDXX) => {
    await insertCompanyAreaSerie({ EMPNITXX: EMPNITXX, AREAIDXX: AREAIDXX, SERIEIDX: serieID })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  const updateCompanyAreaSerie = async (serieID, companyArea) => {
    // Mapear el array companyArea para obtener solo las propiedades AREAIDXX y EMPNITXX
    const formattedRelations = companyArea.map(relation => ({
        AREAIDXX: relation.AREAIDXX,
        EMPNITXX: relation.EMPNITXX
    }));

    await upCompanyAreaSerie({ COMPAREA: formattedRelations, SERIEIDX: serieID })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  const addRelation = () => {
    setCompanyArea([...companyArea, { EMPNITXX: "", AREAIDXX: "" }]);
  };

  const removeRelation = (index) => {
    if (companyArea.length > 1) {
      setCompanyArea(companyArea.filter((_, i) => i !== index));
    }
  };

  const handleCompanyChange = (index, value) => {
    const newRelations = [...companyArea];
    if (newRelations.some((relation, i) => i !== index && relation.EMPNITXX === value && relation.AREAIDXX === newRelations[index].AREAIDXX)) {
      alert('Esta combinación de empresa y proceso ya ha sido seleccionada.');
      return;
    }
    newRelations[index].EMPNITXX = value;
    setCompanyArea(newRelations);

    if (value !== "") {
      findAreasByCompany({ EMPNITXX: value, AREAIDXX: "null" }).unwrap().then((data) => {
        const updatedRelations = [...newRelations];
        updatedRelations[index].areas = data.data;
        setCompanyArea(updatedRelations);
      });
    }
  };

  const handleAreaChange = (index, value) => {
    const newRelations = [...companyArea];
    if (newRelations.some((relation, i) => i !== index && relation.EMPNITXX === newRelations[index].EMPNITXX && relation.AREAIDXX === value)) {
      alert('Esta combinación de empresa y proceso ya ha sido seleccionada.');
      return;
    }
    newRelations[index].AREAIDXX = value;
    setCompanyArea(newRelations);
  };

  useEffect(() => {
    if (!isCreating && row) {
      setDescriptionPath(row.SERIEPAT);
      setSerieDescription(row.SERIEDES);
      setSerieId(row.SERIEIDX);
      triggerGetSerieEmpresa({ EMPNITXX: "null", AREAIDXX: "null", SERIEIDX: row.SERIEIDX })
        .unwrap()
        .then((response) => {
          const formattedRelations = response.data.map(item => ({
            EMPNITXX: item.EMPNITXX,
            companyLabel: item.EMPDESXX,
            AREAIDXX: item.AREAIDXX,
            areaLabel: item.AREADESX,
            areas: [] // Inicializar la lista de áreas vacía
          }));

          setCompanyArea(formattedRelations);

          formattedRelations.forEach((relation, index) => {
            if (relation.EMPNITXX) {
              findAreasByCompany({ EMPNITXX: relation.EMPNITXX, AREAIDXX: "null" })
                .unwrap()
                .then((data) => {
                  const updatedRelations = [...formattedRelations];
                  updatedRelations[index].areas = data.data;
                  setCompanyArea(updatedRelations);
                })
                .catch((error) => console.error("Error al cargar áreas:", error));
            }
          });
        })
        .catch((error) => console.error("Error al cargar las series para edición:", error));
    }
  }, [isEdit, row, triggerGetSerieEmpresa, findAreasByCompany, isCreating]);


  return isLoadingSerie || isLoadingCompanyAreaSerie || isLoadingUpdateSerie || isLoadingAreasSeries ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Serie*</Form.Label>
          <InputGroup>
            <Form.Control
              value={serieDescription}
              type="text"
              required
              disabled={isEdit}
              onChange={(e) => {
                setSerieDescription(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre de la Serie</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              disabled={!isCreating}
              minLength={8}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      {companyArea.map((relation, index) => (
        <Row md={12} className="mt-3" key={index}>
          <Form.Group as={Col} md={5}>
            <Form.Label>Empresa:*</Form.Label>
            <Form.Select
              value={relation.EMPNITXX}
              required
              disabled={isEdit}
              onChange={(e) => handleCompanyChange(index, e.target.value)}
            >
              <option value={""}>[SELECCIONE]</option>
              {isLoadingCompanys ? (
                <option value={""}>CARGANDO...</option>
              ) : (
                companys?.data?.map((EMPNITXX) => (
                  <option key={EMPNITXX.EMPNITXX} value={EMPNITXX.EMPNITXX}>
                    {EMPNITXX.EMPDESXX}
                  </option>
                ))
              )}
            </Form.Select>

            <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={5}>
            <Form.Label>Proceso:*</Form.Label>
            <InputGroup>
            <Form.Select
              onChange={(e) => handleAreaChange(index, e.target.value)}
              value={relation.AREAIDXX}
              disabled={isEdit}
            >
              <option value={""}>[SELECCIONE]</option>
              {relation.areas && relation.areas.length > 0 ? (
                relation.areas.map((AREAIDXX) => (
                  <option key={`${AREAIDXX.EMPNITXX}-${AREAIDXX.AREAIDXX}`} value={AREAIDXX.AREAIDXX}>
                    {AREAIDXX.AREADESX}
                  </option>
                ))
              ) : (
                <option value={""}>No hay áreas disponibles</option>
              )}
            </Form.Select>

              {index === companyArea.length - 1 && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ms-2 rounded-circle"
                  onClick={addRelation}
                  disabled={isEdit}
                  style={{ width: "30px", height: "30px", color: "white", backgroundColor: "#007bff", border: "none" }}
                >
                  +
                </Button>
              )}
              <Button
                variant="outline-secondary"
                size="sm"
                className="ms-2 rounded-circle bg-secondary text-white"
                onClick={() => removeRelation(index)}
                disabled={companyArea.length === 1 || isEdit}
                style={{ width: "30px", height: "30px", border: "none" }}
              >
                ×
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">Debe Seleccionar un Proceso</Form.Control.Feedback>
          </Form.Group>
        </Row>
      ))}
      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          <Button type="Submit" disabled={isEdit}>Guardar</Button>
        </Col>
        <Col md={1}>
          <Button
            onClick={() => {
              setValidated(false);
              setSerieDescription("");
              setDescriptionPath("");
              setCompanyArea([{ EMPNITXX: "", AREAIDXX: "" }]);
            }}
            disabled={isEdit}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Creserxx;
