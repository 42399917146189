import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    companySelected: "",
    areaSelected: "",
    serieSelected: "",
    typeSelected: "",
    metadataSelected: "",
    filters: "",
    cargoSelected: "",
    userSelected: "",
    permisoSelected: "",
    typeParentSelected: "",
  },
  reducers: {
    setCompanySelected: (state, action) => {
      state.companySelected = action.payload;
    },
    setAreaSelected: (state, action) => {
      state.areaSelected = action.payload;
    },
    setSerieSelected: (state, action) => {
      state.serieSelected = action.payload;
    },
    setTypeSelected: (state, action) => {
      state.typeSelected = action.payload;
    },
    setMetadataSelected: (state, action) => {
      state.metadataSelected = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetValues: (state) => {
      state.companySelected = "";
      state.areaSelected = "";
      state.serieSelected = "";
      state.typeSelected = "";
      state.metadataSelected = "";
      state.filters = "";
      state.cargoSelected = "";
      state.permisoSelected = "";
      state.userSelected = "";
      state.typeParentSelected = "";
    },
    setCargoSelected: (state, action) => {
      state.cargoSelected = action.payload;
    },
    setUserSelected: (state, action) => {
      state.userSelected = action.payload;
    },
    setPermisoSelected: (state, action) => {
      state.permisoSelected = action.payload;
    },
    setTypeParentSelected: (state, action) => {
      state.typeParentSelected = action.payload;
    },
  },
});

export const {
  setCompanySelected,
  setAreaSelected,
  setSerieSelected,
  setTypeSelected,
  setMetadataSelected,
  resetValues,
  setFilters,
  setCargoSelected,
  setUserSelected,
  setPermisoSelected,
  setTypeParentSelected,
} = filterSlice.actions;

export default filterSlice.reducer;

export const getCompanySelected = (state) => {
  return state.filter.companySelected;
};
export const getAreaSelected = (state) => {
  return state.filter.areaSelected;
};

export const getSerieSelected = (state) => {
  return state.filter.serieSelected;
};
export const getTypeSelected = (state) => {
  return state.filter.typeSelected;
};
export const getMetadataSelected = (state) => {
  return state.filter.metadataSelected;
};
export const getFilters = (state) => {
  return state.filter.filters;
};
export const getCargoSelected = (state) => {
  return state.filter.cargoSelected;
}
export const getUserSelected = (state) => {
  return state.filter.userSelected;
}
export const getPermisoSelected = (state) => {
  return state.filter.permisoSelected;
}
export const getTypeParentSelected = (state) => {
  return state.filter.typeParentSelected;
};

export const createFiltersBy = (state) => {
  let filters = "";
  if (state.filter.companySelected !== "") {
    filters += "filters[EMPNITXX]=" + state.filter.companySelected + "&";
  }
  if (state.filter.areaSelected !== "") {
    filters += "filters[AREAIDXX]=" + state.filter.areaSelected + "&";
  }
  if (state.filter.serieSelected !== "") {
    filters += "filters[SERIEIDX]=" + state.filter.serieSelected + "&";
  }
  if (state.filter.typeSelected !== "") {
    filters += "filters[TIPOIDXX]=" + state.filter.typeSelected + "&";
  }
  if (state.filter.metadataSelected !== "") {
    filters += "filters[METIDXXX]=" + state.filter.metadataSelected + "&";
  }
  if (state.filter.typeParentSelected !== "") {
    filters += "filters[GEST0007.TIPOIDPA]=" + state.filter.typeParentSelected + "&";
  }
  if (state.filter.cargoSelected !== "") {
    filters += "filters[CARIDXXX]=" + state.filter.cargoSelected + "&";
  }
  if (state.filter.userSelected !== "") {
    filters += "filters[USRIDXXX]=" + state.filter.userSelected + "&";
  }
  if (state.filter.permisoSelected !== "") {
    filters += state.filter.permisoSelected + "&";
  }
  return filters.substring(0, filters.length - 1);
};
