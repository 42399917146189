import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetOptionsQuery, useCreateMetadataMutation, useUpdateMetadataMutation } from "../../libs/redux/slices/cremetxxSlice/cremetxxApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";

const Cremetxx = ({ onClose, isCreating, row, isEdit }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const { data: dataOptions, isSuccess: isSuccessOptions } = useGetOptionsQuery();
  const [insertMetadata, { isLoading: isLoadingMetadata }] = useCreateMetadataMutation();
  const [updateMetadata, { isLoading: isLoadingUpMetadata }] = useUpdateMetadataMutation();

  const [validated, setValidated] = useState(false);
  const [invalidOptions, setInvalidOptions] = useState(false);
  const [descriptionMetadata, setDescriptionMetadata] = useState("");
  const [metadataType, setMetadataType] = useState("");
  const [machineName, setMachineName] = useState("");
  const [options, setOptions] = useState("");
  const [classHide, setClassHide] = useState("d-none");
  const [placeHolder, setPlaceHolder] = useState("");
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (
      metadataType === "LISTASELECCION" ||
      metadataType === "LISTASELECCIONFUNCION" ||
      metadataType === "LISTASELECCIONFUNCIONMULTIPLE"
    ) {
      if (options === "") {
        setInvalidOptions(true);
        return;
      }
    }
    if (form.checkValidity()) {
      if (!isCreating) {
        await updateMetadata({
          METDESXX: descriptionMetadata,
          METIDXXX: machineName,
          METTIPXX: metadataType,
          METOPXXX: options,
          REGESTXX: "ACTIVO"
        })
          .unwrap()
          .then(() => {
            onClose(false);
          })
          .catch((reject) => {
            console.log("rechazado");
          });
      } else {
        await insertMetadata({
          METDESXX: descriptionMetadata,
          METIDXXX: machineName,
          METTIPXX: metadataType,
          METOPXXX: options,
        })
          .unwrap()
          .then(() => {
            onClose(false);
          })
          .catch((reject) => {
            console.log("rechazado");
          });
      }
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };
  useEffect(() => {
    if (row) {
      setDescriptionMetadata(row.METDESXX || "");
      setMachineName(row.METIDXXX || "");
      setMetadataType(row.METTIPXX || "");
      setOptions(row.METOPXXX || "");
      switch (row.METTIPXX) {
        case "LISTASELECCION":
          setPlaceHolder("Opciones Separadas por ,");
          setClassHide("");
          break;
        case "LISTASELECCIONFUNCION":
        case "LISTASELECCIONFUNCIONMULTIPLE":
          setPlaceHolder("Nombre de la Funcion");
          setClassHide("");
          break;
        default:
          setClassHide("d-none");
          break;
      }
    }
  }, [row]);

  return !isSuccessOptions || isLoadingMetadata || isLoadingUpMetadata ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Metadato*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionMetadata}
              type="text"
              required
              onChange={(e) => {
                setDescriptionMetadata(e.target.value);
              }}
              disabled={isEdit}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Metadato</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Nombre Maquina del Metadato*</Form.Label>
          <InputGroup>
            <Form.Control
              value={machineName}
              type="text"
              onChange={(e) => {
                setMachineName(e.target.value);
              }}
              required
              minLength={8}
              maxLength={8}
              disabled={!isCreating}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre Maquina</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo de Campo:*</Form.Label>
          <Form.Select
            value={metadataType}
            required
            disabled={isEdit}
            onChange={(e) => {
              setMetadataType("");
              let metaDataSelected = "";
              for (var i = 0; i < e.target.options.length; i++) {
                if (e.target.options[i].selected && e.target.options[i].value !== "") {
                  metaDataSelected = e.target.value;
                }
              }
              setMetadataType(metaDataSelected);
              switch (metaDataSelected) {
                case "LISTASELECCION":
                  setPlaceHolder("Opciones Separadas por ,");
                  setClassHide("");
                  break;
                case "LISTASELECCIONFUNCION":
                case "LISTASELECCIONFUNCIONMULTIPLE":
                  setPlaceHolder("Nombre de la Funcion");
                  setClassHide("");
                  break;
                default:
                  setClassHide("d-none");
                  break;
              }
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {isSuccessOptions
              ? Object.keys(dataOptions.data.options).map((value) => {
                  return (
                    <option value={value} key={value}>
                      {dataOptions.data.options[value]}
                    </option>
                  );
                })
              : "CARGANDO..."}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un tipo de campo </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4} className={classHide}>
          <Form.Label>{placeHolder}*</Form.Label>
          <InputGroup>
            <Form.Control
              as="textarea"
              value={options}
              onChange={(e) => {
                setOptions(e.target.value);
              }}
              isInvalid={invalidOptions}
              disabled={isEdit}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">Debe Diligenciar las Opciones o la Funcion</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          <Button type="Submit" disabled={isEdit}>Guardar</Button>
        </Col>
        <Col md={1}>
          <Button
            onClick={(e) => {
              setValidated(false);
              setMetadataType("");
              setMachineName("");
              setMetadataType("");
              setOptions("");
              setClassHide("d-none");
            }}
            disabled={isEdit}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Cremetxx;
